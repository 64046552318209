// toplogo
$(function() {
    const className = 'active';

    let $window = $(window);
    let $target = $('.toplogo p');

    if ($target.length > 0) {
        $window.on('load', () => {
            setTimeout(() => {
                $target.removeClass(className);
            }, 1000);
        });
    }
});


// スクロール
$(function() {
    const SPEED: number = 400;
    let $target: JQuery = $('a[href^="#"]');

    $target.on('click', (e: JQuery.Event) => {
        let href = $(e.currentTarget).attr("href");
        let target = $(href == "#" || href == "" ? 'html' : href);
        let  position = target.offset().top;
        $('body,html').animate({ scrollTop:position }, SPEED, 'swing');
        return false;
    });
});


// メニュー
$(function() {
    const className: string = 'js-active';
    const classMenu: string = 'global-menu-list';
    const classOver: string = 'global-menu-over';
    const speed: number = 400;

    let $document: JQuery = $(document);
    let $trigger: JQuery = $('.js-trigger-menu');
    let $menu: JQuery = $('.js-menu');
    let $over: JQuery = $('.' + classOver);

    // トリガーのクリックでメニュー開閉
    $trigger.on('click', (event) => {
        event.stopPropagation(); // イベント伝播を止める
        if ($menu.hasClass(className)) {
            close();
        } else {
            open();
        }
    });

    // メニュー以外をクリックするとメニューを閉じる
    $document.on('click', (event) => {
        if ($menu.hasClass(className)) {
            if (!$(event.target).closest(`.${classMenu}`).length) {
                close();
            }
        }
    });

    /**
     * メニューを閉じる
     */
    function close(): void {
        $menu.removeClass(className);
        $trigger.removeClass(className);
        $over.hide(0);
    }

    /**
     * メニューを開く
     */
    function open(): void {
        $menu.addClass(className);
        $trigger.addClass(className);
        $over.show(0);
    }
});
